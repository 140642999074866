<form-popup [(visible)]="visible" (save)="onSaveClick()" [titleText]="titleText" (visibleChange)="handleVisible($event)"
  height="auto" width="50vw" (onShown)="onShown()">
  <dx-form [(formData)]="entity">
    <dxi-item itemType="group" colCount="3" [colSpan]="2">
      <dxi-item dataField="Label" editorType="dxTextBox" [colSpan]="3" />
      <dxi-item dataField="ServiceId" editorType="dxLookup" [colSpan]="1" [editorOptions]="{
          dataSource: services,
          displayExpr: 'ServiceTypeLabel',
          valueExpr: 'ServiceTypeId',
          searchEnabled: true,
          validationMessageMode: 'always',
        }">
        <dxi-validation-rule type="required" message="Service is required" />
        <dxo-label text="Service" />
      </dxi-item>
      <dxi-item dataField="CaseTypeId" editorType="dxLookup" [colSpan]="1" [editorOptions]="{
          dataSource: caseTypes,
          displayExpr: 'CaseTypeLabel',
          valueExpr: 'CaseTypeId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }">
        <dxi-validation-rule type="required" message="Case Type is required" />
        <dxo-label text="Case Type" />
      </dxi-item>
      <dxi-item dataField="CountryId" editorType="dxLookup" [colSpan]="1" [editorOptions]="{
          dataSource: countries,
          searchEnabled: true,
          displayExpr: 'StateLabel',
          valueExpr: 'StateId',
          validationMessageMode: 'always',
        }">
        <dxi-validation-rule type="required" message="Country is required" />
        <dxo-label text="Country" />
      </dxi-item>
      <dxi-item dataField="ClassSelectionRequired" editorType="dxCheckBox" [colSpan]="1">
      </dxi-item>
      <dxi-item dataField="DesignationSelectionRequired" editorType="dxCheckBox" [colSpan]="1">
      </dxi-item>
      <!-- Placeholder for the last column. -->
      <dxi-item [colSpan]="1">
      </dxi-item>

    </dxi-item>
    <dxi-item itemType="group" caption="Workcodes" [colSpan]="3">
      <fee-calculator-billing-matrix-lines
        [caseTypeId]="entity?.CaseTypeId"
        [source]="lines"
        [readOnly]="!hasParametersFilledIn()"
        [filteredWorkCodes]="filteredWorkCodes" />
    </dxi-item>
  </dx-form>
</form-popup>
