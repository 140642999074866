import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxScrollViewModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { FeeCalculatorFormModule } from 'src/app/components/fee-calculator/fee-calculator-form/fee-calculator-form.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'fee-calculator',
  templateUrl: './fee-calculator.component.html',
  styleUrls: ['./fee-calculator.component.scss'],
})
export class FeeCalculatorComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: DataSource;
  url: String;

  organizationCompleted: boolean = false;
  servicesCompleted: boolean = false;

  showFilterRow: boolean = false;

  constructor(private FeeCalculatorSvc: FeeCalculatorService) {
    this.url = FeeCalculatorSvc.getUrl();
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/quote`,
      }),
    });
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  newQuote = () => {
    this.FeeCalculatorSvc.CreateNewQuote().subscribe({
      next: (x) => {
        //redirect to fee-calculator-wizard in new tab
        window.open('/#/fee-calculator-wizard/' + x.Id, '_blank');
        this.refreshData();
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
    });
  };

  onEdit = (e: any) => {
    const selectedRow = e.row.data;

    if (selectedRow) {
      window.open('/#/fee-calculator-wizard/' + selectedRow.Id, '_blank');
    } else {
      notify('Please select a row to edit', 'Error', 3000);
    }
  };

  filterGridShow = (e: any) => {
    this.showFilterRow = !this.showFilterRow;

    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}/quote`,
        loadParams: { ShowAll: this.showFilterRow },
      }),
    });
  };
}
@NgModule({
  imports: [
    CommonModule,
    FeeCalculatorFormModule,
    TitleBarModule,
    DxScrollViewModule,
    DxDataGridModule,
    DxCheckBoxModule,
    DxButtonModule,
  ],
  exports: [],
  declarations: [FeeCalculatorComponent],
  providers: [],
})
export class FeeCalculatorModule {}
