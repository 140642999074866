import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxFormModule,
  DxTextAreaModule,
  DxValidatorModule,
} from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { QuoteService } from 'src/app/services/financial/quote/quote.service';
import { Charge } from 'src/app/types/invoicing/charge';
import DataSource from 'devextreme/data/data_source';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { WorkcodeService } from 'src/app/services/invoicing/workcode/workcode.service';
import { CaseService } from 'src/app/services/case/case.service';
import { VatService } from 'src/app/services/vat.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'finance-charge-new',
  templateUrl: './finance-charge-new.component.html',
  styleUrls: ['./finance-charge-new.component.scss'],
})
export class FinanceChargeNewComponent implements OnInit {
  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<Charge>();

  @Output() visibleChange = new EventEmitter<boolean>();

  charge: any;

  CurrentCaseId: number = 0;
  CurrentWorkCodeId: number = 0;

  vats: number[];

  currencies: any;

  workcodeDataSource: any;
  caseDataSource: any;

  clientLanguageId: number = 3;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(
    private QuoteSvc: QuoteService,
    private WorkCodeSvc: WorkcodeService,
    private CaseSvc: CaseService,
    private vatService: VatService
  ) {
    this.reCalculateQuote = this.reCalculateQuote.bind(this);
    this.reCalculateAmounts = this.reCalculateAmounts.bind(this);

    this.vats = this.vatService.getVATPercentageList();
  }

  ngOnInit(): void {
    this.resetEntity();

    this.workcodeDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${this.url}Invoice/Workcode/lookup`,
      }),
      sort: 'WorkCodeLabel',
    });

    this.caseDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseId',
        loadUrl: `${this.url}Patricia/CaseDetailedInfo/lookup`,
      }),
      sort: 'CaseNumber',
    });

    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'CurrencyId',
        loadUrl: `${this.url}Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
      filter: ['CurrencyId', '=', 1],
    });
  }

  reCalculateQuote = (): void => {
    if (this.charge.CaseId != null && this.charge.WorkCodeId != null) {
      this.QuoteSvc.CalculateQuote(
        this.charge.WorkCodeId,
        this.charge.CaseId
      ).subscribe({
        next: (res) => {
          this.charge.UnitPrice = res.Amount;
          this.charge.VatPercentage = res.VatPercentage;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
    }
  };

  reCalculateAmounts(): void {
    this.charge.NonVatAmount = this.charge.UnitPrice * this.charge.Quantity;

    this.charge.VatAmount =
      this.charge.UnitPrice *
      this.charge.Quantity *
      (this.charge.VatPercentage / 100);

    this.charge.AmountTot = this.charge.NonVatAmount + this.charge.VatAmount;
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.resetEntity();
    this.visibleChange.emit(this.visible);
  }

  onSaveClick(): void {
    this.save.emit(this.charge);

    this.resetEntity();
  }

  onDescriptionChange = (e: any) => {
    this.charge.Description = e.value;
  };

  resetEntity(): void {
    this.charge = {
      Quantity: 1,
      UnitPrice: 0,
      NonVatAmount: 0,
      VatAmount: 0,
      AmountTot: 0,
      CurrencyId: 1,
    };
  }

  reCalculateTotalAmount = (): void => {
    this.charge.AmountTot = this.charge.NonVatAmount + this.charge.VatAmount;
  };

  onCaseChanged = (data) => {
    const value = data.value;

    this.charge.CaseId = value;

    this.reCalculateQuote();

    if (value) {
      this.getCaseClientLanguagePromise(value).then((data) => {
        this.clientLanguageId = data;
      });
      this.vatService;
    }
  };

  onWorkcodeChanged = (data) => {
    const value = data.value;
    this.charge.WorkCodeId = value;
    if (value == null) {
      return;
    }

    this.reCalculateQuote();

    return this.getWorkCodeDescriptionPromise(value).then((data: any) => {
      this.charge.Description = data.WorkCodeDescription;
    });
  };

  getCaseClientLanguagePromise = (caseId): Promise<number> => {
    return new Promise((resolve) => {
      this.CaseSvc.getAccountAddressLanguage(caseId).subscribe((data) => {
        resolve(data.LanguageId);
      });
    });
  };

  getWorkCodeDescriptionPromise = (workCodeId) => {
    return new Promise((resolve) => {
      this.WorkCodeSvc.getWorkCode(workCodeId, this.clientLanguageId).subscribe(
        (data) => {
          resolve({
            WorkCodeDescription: data.InvoiceDescription,
            WorkCodeLabel: data.WorkCodeLabel,
          });
        }
      );
    });
  };
}

@NgModule({
  declarations: [FinanceChargeNewComponent],
  exports: [FinanceChargeNewComponent],
  bootstrap: [FinanceChargeNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxTextAreaModule,
  ],
})
export class FinanceChargeNewModule {}
