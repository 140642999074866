<div class="cards kpi">
  <financial-valuta-kpi
    [total]="totals.TotalAmountDue"
    [title]="'Total Amount Due (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountDueNonVat"
  />
  <financial-valuta-kpi
    [total]="totals.TotalAmountOverdue"
    [title]="'Total Amount Overdue (VAT incl.)'"
    [detailTitle]="'(excl.)'"
    [detailTotal]="totals.TotalAmountOverdueNonVat"
  />
  <financial-number-kpi
    [total]="totals.InvoicesDue"
    [title]="'# Invoices Due'"
  />
  <financial-number-kpi
    [total]="totals.InvoicesOverdue"
    [title]="'# Invoices Overdue'"
  />
</div>
<div class="separator"></div>
<div class="amountdueGrid">
  <dx-data-grid
    [dataSource]="amountDueDataSource"
    height="80vh"
    [rowAlternationEnabled]="true"
  >
    <dxo-scrolling mode="virtual" rowRenderingMode="virtual"></dxo-scrolling>
    <dxi-column dataField="ClientId" caption="Client ID" [visible]="false">
    </dxi-column>
    <dxi-column
      dataField="Client"
      caption="Client"
      [sortIndex]="1"
      sortOrder="asc"
    >
    </dxi-column>
    <dxi-column dataField="PaymentTerms" caption="Payment Terms" />
    <dxi-column dataField="InvoicesDue" caption="# Invoices Due" />
    <dxi-column dataField="InvoicesOverdue" caption="# Invoices Overdue" />
    <dxi-column dataField="AmountDueNonVat" caption="Amount Due (excl.)">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountDue1" caption="Amount Due">
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column
      dataField="AmountOverDueNonVat"
      caption="Amount Overdue (excl.)"
    >
      <dxo-format type="currency" precision="2" />
    </dxi-column>
    <dxi-column dataField="AmountOverDue" caption="Amount Overdue">
      <dxo-format type="currency" precision="2" />
    </dxi-column>

    <dxo-master-detail [enabled]="true" template="detail" />
    <div *dxTemplate="let details of 'detail'">
      <finance-creditcollect-amoundue-details
        [clientId]="details.data.ClientId"
      />
    </div>
  </dx-data-grid>
</div>
