<form-popup
  [(visible)]="visible"
  (save)="onSaveClick()"
  [titleText]="titleText"
  (visibleChange)="handleVisible($event)"
  (onShow)="onShow()"
>
  <dx-form [(formData)]="charge" height="80vh">
    <dxi-item
      itemType="group"
      cssClass="second-group"
      [colCount]="2"
      caption="General"
    >
      <dxi-item
        dataField="CaseId"
        editorType="dxLookup"
        [disabled]="true"
        [editorOptions]="{
          dataSource: caseDataSource,
          displayExpr: 'CaseNumber',
          valueExpr: 'CaseId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxo-label text="Case" />
      </dxi-item>
      <dxi-item
        dataField="WorkCodeId"
        editorType="dxLookup"
        [editorOptions]="{
          dataSource: workcodeDataSource,
          displayExpr: 'WorkCodeFullDescription',
          valueExpr: 'WorkCodeId',
          searchEnabled: true,
          onValueChanged: onWorkcodeChanged,
          validationMessageMode: 'always',
          disabled: hasCost()
        }"
      >
        <dxo-label text="WorkCode" />
      </dxi-item>
      <dxi-item
        dataField="UnitPrice"
        editorType="dxNumberBox"
        [editorOptions]="{
          onValueChanged: reCalculateAmounts,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Unit price is required" />
      </dxi-item>
      <dxi-item
        dataField="Quantity"
        editorType="dxNumberBox"
        [editorOptions]="{
          min: '0',
          onValueChanged: reCalculateAmounts,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Quantity is required" />
      </dxi-item>
      <dxi-item
        dataField="VatPercentage"
        editorType="dxSelectBox"
        [editorOptions]="{
          items: vats,
          validationMessageMode: 'always',
          onValueChanged: reCalculateAmounts
        }"
      >
        <dxi-validation-rule
          type="required"
          message="VAT percentage is required"
        />
      </dxi-item>
    </dxi-item>
    <dxi-item itemType="group" [colCount]="2" caption="Financial">
      <dxi-item
        dataField="NonVatAmount"
        editorType="dxNumberBox"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' },
          onValueChanged: reCalculateTotalAmount,
        }"
      />
      <dxi-item
        dataField="VatAmount"
        editorType="dxNumberBox"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' },
          onValueChanged: reCalculateTotalAmount,
        }"
      />
      <dxi-item
        dataField="AmountTot"
        editorType="dxNumberBox"
        [disabled]="true"
        [editorOptions]="{
          validationMessageMode: 'always',
          format: { precision: 2, type: 'fixedPoint' }
        }"
      />
      <dxi-item
        dataField="CurrencyId"
        editorType="dxLookup"
        [disabled]="true"
        [editorOptions]="{
          dataSource: currencies,
          displayExpr: 'CurrencyLabel',
          valueExpr: 'CurrencyId',
          searchEnabled: true,
          validationMessageMode: 'always'
        }"
      >
        <dxi-validation-rule type="required" message="Currency is required" />
        <dxo-label text="Currency" />
      </dxi-item>
      <dxi-item dataField="Description" [colSpan]="2">
        <dx-text-area
          validationMessageMode="always"
          [autoResizeEnabled]="true"
          [(value)]="description"
          (onValueChanged)="onDescriptionChange($event)"
        />
        <dxo-label text="Description" />
        <dxi-validation-rule
          type="required"
          message="Description is required"
        />
      </dxi-item>
    </dxi-item>
    <dxi-item
      itemType="group"
      caption="Time Details"
      [colCount]="2"
      [colSpan]="2"
    >
      <registration-details-form
        [datasource]="timeDetailsDataSource"
        [allowAdd]="false"
      />
    </dxi-item>
  </dx-form>
</form-popup>
