import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxDateBoxModule,
  DxFormModule,
  DxPopupModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { InvoiceService } from 'src/app/services/invoicing/Invoice/InvoiceService.service';
import { finalize } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';

@Component({
  selector: 'finance-creditcollect-topay-details',
  templateUrl: './finance-creditcollect-topay-details.component.html',
  styleUrls: ['./finance-creditcollect-topay-details.component.scss'],
})
export class FinanceCreditcollectToPayDetailsComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() providerId: number;

  @Input() amountOverdue: number;

  @Output() refreshDataEvent = new EventEmitter();

  detailsDataSource: DataSource;

  costStatusDataSource: DataSource;

  dataSource: DataSource<any, any>;

  showPaidPopup = false;

  url = environment.CalystaApiBaseURL + 'api/';

  paidData: any = {};

  constructor(
    private invoiceService: InvoiceService,
    private patriciaService: PatriciaService
  ) {
    this.paidData.Status = null;
    this.paidData.CostHeaderId = null;
  }

  ngOnInit() {
    this.detailsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostHeaderId',
        loadUrl: `${this.url}CreditCollect/InvoicesByProvider`,
        loadParams: { ProviderId: this.providerId },
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.costStatusDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CostStatusId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Costs/CostStatus/Lookup`,
      }),
    });
  }

  openDocument = (e) => {
    window.open(e.row.data.InvoiceDocument, '_blank');
  };

  openPaidPopup = (e) => {
    this.showPaidPopup = true;
    this.paidData.CostHeaderId = e.row.data.CostHeaderId;
    this.paidData.Status = e.row.data.CostStatusId;
  };

  ConfirmPaid = () => {
    this.showPaidPopup = false;

    //send to api
    this.invoiceService
      .setCostStatus(this.paidData.CostHeaderId, this.paidData.Status)
      .pipe(
        finalize(() => {
          this.refreshData();

          //reset data
          this.paidData.Status = null;
          this.paidData.CostHeaderId = null;
        })
      )
      .subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
  };

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  CancelPaid = () => {
    this.showPaidPopup = false;

    //reset data
    this.paidData.Status = null;
    this.paidData.CostHeaderId = null;
  };
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxPopupModule,
    DxFormModule,
    DxDateBoxModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectToPayDetailsComponent],
  exports: [FinanceCreditcollectToPayDetailsComponent],
})
export class FinanceCreditcollectToPayDetailsModule {}
