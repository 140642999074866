import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxTagBoxModule,
  DxValidatorModule,
} from 'devextreme-angular';
import { environment } from 'src/environments/environment';
import { RwfollowUp } from 'src/app/types/invoicing/rwFollowUp';
import { WorkcodeLookupModule } from 'src/app/components/lookups/workcode-lookup/workcode-lookup.component';
import { FormPopupModule } from 'src/app/components/utils/form-popup/form-popup.component';
import { BudgetSalesTargetContributorModule } from '../../budget/budget-sales-target-contributors/budget-sales-target-contributors.component';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorBillingMatrixLinesModule } from '../fee-calculator-billing-matrix-lines/fee-calculator-billing-matrix-lines.component';

@Component({
  selector: 'fee-calculator-billing-matrix-popup',
  templateUrl: './fee-calculator-billing-matrix-popup.component.html',
  styleUrls: ['./fee-calculator-billing-matrix-popup.component.scss'],
})
export class FeeCalculatorBillingMatrixPopupComponent implements OnInit {
  @Input() entity: any;

  @Input() titleText = '';

  @Input() visible = false;

  @Output() save = new EventEmitter<RwfollowUp>();

  @Output() visibleChange = new EventEmitter<boolean>();

  services: DataSource;

  caseTypes: DataSource;

  workCodes: DataSource;

  filteredWorkCodes: DataSource;

  countries: DataSource;

  url = environment.CalystaApiBaseURL + 'api/';

  lines: any;

  constructor() {}

  ngOnInit(): void {
    this.services = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/FeeCalculator/ServiceType`,
      }),
      sort: 'ServiceTypeLabel',
    });

    this.caseTypes = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Case/CaseType/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'CaseTypeLabel',
    });

    this.countries = new DataSource({
      store: AspNetData.createStore({
        key: 'StateId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Patricia/State/Lookup`,
        onBeforeSend(method, ajaxOptions) {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
      sort: 'StateLabel',
    });

    this.workCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit(this.entity);
  }

  onShown(): void {
    this.lines = this.entity?.BillingMatrixWorkCodeLines;
  }

  hasParametersFilledIn = (): boolean => {
    return (
      this.entity?.CountryId !== null &&
      this.entity?.ServiceId !== null &&
      this.entity?.CaseTypeId !== null
    );
  };
}

@NgModule({
  declarations: [FeeCalculatorBillingMatrixPopupComponent],
  exports: [FeeCalculatorBillingMatrixPopupComponent],
  bootstrap: [FeeCalculatorBillingMatrixPopupComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    DxTagBoxModule,
    FeeCalculatorBillingMatrixLinesModule,
  ],
})
export class FeeCalculatorBillingMatrixPopupModule {}
