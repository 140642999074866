<div>
  <dx-data-grid
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    id="gridContainer"
    [dataSource]="source"
    [showBorders]="true"
    [remoteOperations]="true"
    height="35vh"
    (onRowUpdating)="onRowUpdating($event)"
    [wordWrapEnabled]="true"
    [disabled]="readOnly"
  >
    <dxo-toolbar>
      <dxi-item location="after" name="addRowButton" />
      <dxi-item
        location="after"
        widget="dxButton"
        locateInMenu="never"
        [options]="{
          text: '',
          icon: 'refresh',
          stylingMode: 'text',
          onClick: refreshData
        }"
      />
    </dxo-toolbar>

    <dxi-column dataField="WorkCodeId" caption="WorkCode" alignment="center">
      <dxo-lookup
        [dataSource]="workCodes.store()"
        valueExpr="WorkCodeId"
        displayExpr="WorkCodeFullDescription"
      />
    </dxi-column>
    <dxo-editing
      mode="popup"
      [allowUpdating]="true"
      [allowAdding]="true"
      [allowDeleting]="true"
    >
      <dxo-popup title="" [showTitle]="false" width="30vw" height="auto">
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="saveButtonOptions"
        />
        <dxi-toolbar-item
          toolbar="bottom"
          widget="dxButton"
          location="after"
          [options]="cancelButtonOptions"
        />
      </dxo-popup>
      <dxo-form>
        <dxi-item itemType="group" [colCount]="2" [colSpan]="2">
          <dxi-item
            dataField="WorkCodeId"
            editorType="dxLookup"
            [colSpan]="2"
            [editorOptions]="{
              dataSource: workCodes.store(),
              displayExpr: 'WorkCodeFullDescription',
              valueExpr: 'WorkCodeId',
              searchEnabled: true,
              validationMessageMode: 'always',
            }"
          >
            <dxi-validation-rule
              type="required"
              message="WorkCode is required"
            />
            <dxo-label text="WorkCode" />
          </dxi-item>
        </dxi-item>
        <dxi-item *ngIf="caseTypeId !== undefined">
          {{ caseTypeId }}
        <div *ngIf="caseTypeId === 2">
          <p> Here we can put the Patent parameters.</p>
        </div>
        <div *ngIf="caseTypeId === 4">
          <p> Here we can put the Trademark parameters.</p>
        </div>
        </dxi-item>
      </dxo-form>
    </dxo-editing>
  </dx-data-grid>
</div>
