import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import {
  DxFormModule,
  DxLookupModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { CRMService } from 'src/app/services/prospection/crm.service';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'fee-calculator-wizard-organization',
  templateUrl: './fee-calculator-wizard-organization.component.html',
  styleUrls: ['./fee-calculator-wizard-organization.component.scss'],
})
export class FeeCalculatorWizardOrganizationComponent implements OnInit {
  @Input()
  quote: any;

  @Output()
  stepCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  url = environment.CalystaApiBaseURL + 'api/';

  clients: DataSource;

  services: DataSource;

  constructor(
    private crmService: CRMService,
    private feeCalculatorService: FeeCalculatorService
  ) {}

  ngOnInit() {
    this.clients = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}Prospection/CRM/ClientsAndProspects`,
      }),
      sort: 'Name',
    });

    this.services = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceId',
        loadUrl: `${this.url}Patricia/ServiceLevelLookup/Lookup`,
      }),
      sort: 'ServiceLevelLabel',
    });
  }

  onClientChanged = (e: any) => {
    if (e.value?.Id) {
      this.crmService
        .getDetailedInfo(e.value.Id, e.value.IsProspect)
        .subscribe((data) => {
          this.quote.ClientId = e.value.Id;
          this.quote.IsClientProspect = e.value.IsProspect;
          this.quote.Email = data.Email;
          this.quote.Address = data.Address;
          this.quote.City = data.City;
          this.quote.ZipCode = data.ZipCode;
          this.quote.Country = data.Country;
          this.quote.ServiceLevelId = data.ServiceLevelId;

          this.updateQuote();
        });
    }
  };

  /// This function is called when the service level is changed
  onServiceLevelChanged = (e: any) => {
    this.quote.ServiceLevelId = e.value;
    this.updateQuote();
    if (e.value) {
      this.stepCompleted.emit(true);
    } else {
      this.stepCompleted.emit(false);
    }
  };

  updateQuote = () => {
    if (this.quote && this.quote.Id) {
      this.feeCalculatorService.UpdateQuote(this.quote).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
    }
  };
}

@NgModule({
  exports: [FeeCalculatorWizardOrganizationComponent],
  declarations: [FeeCalculatorWizardOrganizationComponent],
  providers: [],
  imports: [
    CommonModule,
    DxTabPanelModule,
    TitleBarModule,
    DxFormModule,
    DxLookupModule,
  ],
})
export class FeeCalculatorWizardOrganizationModule {}
