import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit } from '@angular/core';
import { DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { CreditCollectService } from 'src/app/services/creditcollect/creditcollect.service';
import notify from 'devextreme/ui/notify';
import { FinanceCreditcollectToPayDetailsModule } from './finance-creditcollect-topay-details/finance-creditcollect-topay-details.component';

@Component({
  selector: 'finance-creditcollect-topay',
  templateUrl: './finance-creditcollect-topay.component.html',
  styleUrls: ['./finance-creditcollect-topay.component.scss'],
})
export class FinanceCreditcollectToPayComponent implements OnInit {
  amountDueDataSource: any;
  totals: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor(private creditcollectSvc: CreditCollectService) {}

  ngOnInit() {
    this.amountDueDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProviderId',
        loadUrl: `${this.url}CreditCollect/AmountToPay/All`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });

    this.totals = this.creditcollectSvc.getAmountToPayTotals().subscribe(
      (data) => {
        this.totals = data.Totals;
      },
      (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error getting totals', 3000);
      },
      () => {}
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    FinanceCreditcollectToPayDetailsModule,
    FinancialValutaKpiModule,
    FinancialNumberKpiModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectToPayComponent],
  exports: [FinanceCreditcollectToPayComponent],
})
export class FinanceCreditcollectToPayModule {}
