<dx-popup
  [width]="1200"
  [height]="600"
  [showTitle]="true"
  title="Specify the service for which you want to make a quote"
  [dragEnabled]="true"
  [closeOnOutsideClick]="false"
  [(visible)]="showPopup"
  (onShowing)="onPopupShowing()"
>
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="closeButtonOptions"
  />
  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [options]="addServiceButtonOptions"
  />

  <div *dxTemplate="let data of 'content'">
    <dx-form [formData]="formData" [colCount]="1">
      <dxi-item itemType="group" caption="Service Selection">
        <dxi-item
          dataField="selectedServiceType"
          label="Service Type"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            dataSource: serviceTypesDataSource.store(),
            displayExpr: 'ServiceTypeLabel',
            valueExpr: 'ServiceTypeId',
            onValueChanged: onServiceTypeChanged
          }"
        />

        <dxi-item
          *ngIf="formData?.selectedServiceType"
          dataField="selectedCaseType"
          label="IP Type"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            dataSource: caseTypesDataSource.store(),
            displayExpr: 'CaseTypeLabel',
            valueExpr: 'CaseTypeId',
            onValueChanged: onCaseTypeChanged
          }"
        />

        <dxi-item
          *ngIf="formData?.selectedCaseType"
          dataField="selectedTerritory"
          label="Territory"
          [editorType]="'dxSelectBox'"
          [editorOptions]="{
            dataSource: territoriesDataSource.store(),
            displayExpr: 'StateLabel',
            valueExpr: 'StateId',
            searchEnabled: true,
            onValueChanged: onTerritoryChanged
          }"
        />
      </dxi-item>

      <dxi-item
        *ngIf="classSelectionRequired"
        itemType="group"
        caption="Class Selection"
      >
        <dxi-item
          dataField="numberOfClasses"
          label="Number of Classes"
          [editorType]="'dxNumberBox'"
          [editorOptions]="{
            min: 0,
            showSpinButtons: true
          }"
        />
      </dxi-item>

      <dxi-item
        [editorType]="'dxButton'"
        [editorOptions]="{
          text: 'Get Quote',
          type: 'default',
          stylingMode: 'contained',
          onClick: onGetQuoteClick
        }"
      />

      <dxi-item *ngIf="showWorkcodes" itemType="group" caption="Workcodes">
        <dx-data-grid
          [dataSource]="workcodesDatasource"
          [showBorders]="true"
          [remoteOperations]="true"
          [repaintChangesOnly]="true"
          [wordWrapEnabled]="true"
        >
          <dxo-editing
            mode="cell"
            [allowUpdating]="true"
            [allowAdding]="true"
            [allowDeleting]="true"
          />
          <dxi-column caption="WorkCode" dataField="WorkCodeLabel" />
          <dxi-column caption="Description" dataField="Description" />
          <dxi-column caption="UnitPrice" dataField="UnitPrice" />
          <dxi-column
            caption="Quantity"
            dataField="Quantity"
            [setCellValue]="onQuantityChanged"
          />
          <dxi-column caption="Amount" dataField="Amount" />
        </dx-data-grid>
      </dxi-item>
    </dx-form>
  </div>
</dx-popup>
