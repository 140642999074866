import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { CaseService } from 'src/app/services/case/case.service';
import { FeeCalculatorWizardServicesSelectorModule } from './fee-calculator-wizard-services-selector/fee-calculator-wizard-services-selector.component';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'fee-calculator-wizard-services',
  templateUrl: './fee-calculator-wizard-services.component.html',
  styleUrls: ['./fee-calculator-wizard-services.component.scss'],
})
export class FeeCalculatorWizardServicesComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Output() stepCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() quote: any;

  dataSource: DataSource;
  svcUrl: string;
  serviceTypesDataSource: DataSource;
  caseTypesDataSource: DataSource;
  selectedQuoteLine: any;
  showServicePopup: boolean = false;

  constructor(
    private FeeCalcSvc: FeeCalculatorService,
    private CaseSvc: CaseService
  ) {
    this.svcUrl = FeeCalcSvc.getUrl();
  }

  /// The datasource should be loaded here for quotes that exist already, a new one should be created for new quotes.
  /// This will allow us to save quotes and edit them later on.
  ngOnInit() {
    this.initDataSource();
    this.initServiceTypes();
    this.initCaseTypes();
  }

  initDataSource(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'QuoteLineId',
        loadUrl: `${this.svcUrl}/Quote/QuoteLine`,
        deleteUrl: `${this.svcUrl}/Quote/QuoteLine`,
        loadParams: { QuoteId: this.quote.Id },
      }),
    });
  }

  initServiceTypes(): void {
    this.serviceTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.svcUrl}/serviceType`,
      }),
    });
  }

  initCaseTypes(): void {
    this.caseTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.CaseSvc.getUrl()}/CaseType/Lookup`,
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onEdit = (e: any) => {
    this.selectedQuoteLine = e.row.data;
    console.log(this.selectedQuoteLine);
    this.showServicePopup = true;
  };

  onAdd = () => {
    this.selectedQuoteLine = null;
    this.showServicePopup = true;
  };

  onAddService = (e: any) => {
    if (this.selectedQuoteLine) {
    } else {
      this.FeeCalcSvc.CreateNewQuoteLine(
        this.quote.Id,
        e.selectedCaseType,
        e.selectedServiceType,
        e.selectedTerritory,
        e.WorkCodeLines
      ).subscribe({
        next: (x) => {
          this.dataGrid.instance.refresh();
          this.stepCompleted.emit(true);
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
      });
    }
  };
}

@NgModule({
  exports: [FeeCalculatorWizardServicesComponent],
  declarations: [FeeCalculatorWizardServicesComponent],
  providers: [],
  imports: [
    CommonModule,
    TitleBarModule,
    DxDataGridModule,
    DxButtonModule,
    FeeCalculatorWizardServicesSelectorModule,
  ],
})
export class FeeCalculatorWizardServicesModule {}
