import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxFormModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxToolbarModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FeeCalculatorService } from 'src/app/services/fee-calculator/fee-calculator.service';
import { ValueChangedEvent } from 'devextreme/ui/select_box';
import { CommonModule } from '@angular/common';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'fee-calculator-wizard-services-selector',
  templateUrl: './fee-calculator-wizard-services-selector.component.html',
  styleUrls: ['./fee-calculator-wizard-services-selector.component.scss'],
})
export class FeeCalculatorWizardServicesSelectorComponent implements OnInit {
  serviceTypesDataSource: DataSource;
  caseTypesDataSource: DataSource;
  territoriesDataSource: DataSource;
  workcodesDatasource: DataSource;

  showWorkcodes: boolean = false;
  classSelectionRequired: boolean = false;
  designatedCountrySelectionRequired: boolean = false;

  formData: any = {};

  @Input() showPopup: boolean = false;
  @Input() quote: any;
  @Output() showPopupChange = new EventEmitter<boolean>();

  @Output() onSaveData = new EventEmitter<any>();

  constructor(private FeeCalcSvc: FeeCalculatorService) {}

  ngOnInit() {
    this.initServiceTypes();
    this.initCaseTypes();
    this.initTerritories();
  }

  addServiceButtonOptions = {
    text: 'Add Service',
    type: 'default',
    stylingMode: 'contained',
    onClick: () => {
      this.showPopupChange.emit(false);
      this.onSaveData.emit(this.formData);
      this.formData = {};
    },
  };

  closeButtonOptions = {
    text: 'Close',
    type: 'default',
    onClick: () => {
      this.formData = {};
      this.showPopupChange.emit(false);
      this.showWorkcodes = false;
      this.classSelectionRequired = false;
      this.designatedCountrySelectionRequired = false;
    },
  };

  initServiceTypes = (): void => {
    this.serviceTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ServiceTypeId',
        loadUrl: `${this.FeeCalcSvc.getUrl()}/serviceType`,
      }),
    });
  };

  initCaseTypes = (): void => {
    this.caseTypesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'CaseTypeId',
        loadUrl: `${this.FeeCalcSvc.getUrl()}/NewQuote/CaseType`,
        loadParams: { serviceTypeId: this.formData.selectedServiceType },
      }),
    });
  };

  initTerritories = (): void => {
    this.territoriesDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'StateId',
        loadUrl: `${this.FeeCalcSvc.getUrl()}/NewQuote/Territory`,
        loadParams: {
          serviceTypeId: this.formData.selectedServiceType,
          caseTypeId: this.formData.selectedCaseType,
        },
      }),
      sort: ['StateLabel'],
    });
  };

  onServiceTypeChanged = ($event: ValueChangedEvent) => {
    this.initCaseTypes();
  };

  onCaseTypeChanged = ($event: ValueChangedEvent) => {
    this.initTerritories();
  };

  onTerritoryChanged = ($event: ValueChangedEvent) => {
    if (this.formData.selectedTerritory) {
      this.FeeCalcSvc.DetermineBillingMatrix({
        CaseTypeId: this.formData.selectedCaseType,
        StateId: this.formData.selectedTerritory,
        ServiceTypeId: this.formData.selectedServiceType,
      }).subscribe((data) => {
        this.classSelectionRequired = data.BillingMatrix.ClassSelectionRequired;
        this.designatedCountrySelectionRequired =
          data.BillingMatrix.DesignatedCountrySelectionRequired;

        this.formData.billingMatrixId = data.BillingMatrix.Id;
        this.formData.billingMatrixLabel = data.BillingMatrix.Label;
      });

      this.showWorkcodes = false;
    }
  };

  onGetQuoteClick = () => {
    this.FeeCalcSvc.CalculateWorkCodeQuoteLine({
      BillingMatrixId: this.formData.billingMatrixId,
      ClientId: 1022,
      LanguageId: 2,
      ServiceParameters: {
        CaseTypeId: this.formData.selectedCaseType,
        ServiceTypeId: this.formData.selectedServiceType,
        StateId: this.formData.selectedTerritory,
        DesignatedStates: null,
        NrOfClasses: this.formData.numberOfClasses,
      },
    }).subscribe(
      (data) => {
        this.workcodesDatasource = data.QuoteLines;
        this.formData.WorkCodeLines = data.QuoteLines;
      },
      (error) => {
        console.error(error);
        notify('An error occurred while calculating the quote.', 'error');
      },
      () => {
        this.showWorkcodes = true;
      }
    );
  };

  onQuantityChanged(newData, value, currentRowData) {
    newData.Quantity = value;

    if (currentRowData.UnitPrice != null) {
      let TotalAmount: number = currentRowData.UnitPrice * value;

      newData.Amount = TotalAmount;
    }
  }

  onPopupShowing() {
    console.log(this.quote);
    if (this.quote) {
      this.formData.selectedServiceType = this.quote.ServiceTypeId;
      this.formData.selectedCaseType = this.quote.CaseTypeId;
      this.formData.selectedTerritory = this.quote.CoverageId;
      this.workcodesDatasource = this.quote.QuoteWorkCodeLines;
      this.showWorkcodes = true;
    }
  }
}

@NgModule({
  exports: [FeeCalculatorWizardServicesSelectorComponent],
  declarations: [FeeCalculatorWizardServicesSelectorComponent],
  providers: [],
  imports: [
    DxButtonModule,
    DxPopupModule,
    DxSelectBoxModule,
    DxDataGridModule,
    CommonModule,
    DxNumberBoxModule,
    DxFormModule,
  ],
})
export class FeeCalculatorWizardServicesSelectorModule {}
