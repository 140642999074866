import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxFormModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxToolbarModule,
} from 'devextreme-angular';
import { Invoice } from 'src/app/types/invoicing/invoice';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { CalystaAPIInvoiceService } from 'src/app/services/calysta-api/invoice.service';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { FinanceDraftInvoiceDetailsModule } from './finance-draft-invoice-details/finance-draft-invoice-details/finance-draft-invoice-details.component';
import { CreditLinesSelectionFormModule } from './finance-draft-invoice-details/credit-lines-selection-form/credit-lines-selection-form.component';
import { DraftInvoiceService } from 'src/app/services/invoicing/Invoice/DraftInvoiceService.service';
import { saveAs } from 'file-saver-es';
import { PDFPreviewModule } from '../../library/pdf-preview/pdf-preview.component';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'finance-draft-invoice',
  templateUrl: './finance-draft-invoice.component.html',
  styleUrls: ['./finance-draft-invoice.component.scss'],
})
export class FinanceDraftInvoiceComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() InvoiceHeaderId: number = null;
  @Input() BlobFile: Blob = null;
  @Input() visible = false;
  @Input() isLoading: boolean = false;

  @Output() save = new EventEmitter<Invoice>();

  @Output() loadPdfDocumentChange = new EventEmitter<boolean>();

  @Output() visibleChange = new EventEmitter<boolean>();

  Invoice: Invoice = null;

  cancelDraftButtonOptions: any;
  previewButtonOptions: any;
  finalizeButtonOptions: any;
  editButtonOptions: any;
  creditButtonOptions: any;
  reloadButtonOptions: any;
  downloadButtonOptions: any;
  pdfDocumentLoading: boolean = false;
  serviceLoading: boolean = false;

  url = environment.CalystaApiBaseURL + 'api/';

  invoiceHeaderCategories: DataSource;
  templates: DataSource;
  clients: DataSource;
  currencies: DataSource;

  creditLineSelectionFormVisible = false;

  showWarningPopupReopenCharge = false;

  reopensCharge = false;

  finalizeLoading = false;

  showWarningRegenerateInvoice = false;

  constructor(
    public InvoiceSvc: CalystaAPIInvoiceService,
    private DraftInvoiceSvc: DraftInvoiceService
  ) {
    this.cancelDraftButtonOptions = {
      text: 'Cancel Draft',
      type: 'danger',
      onClick: () => {
        this.onCancelDraft();
      },
    };

    this.previewButtonOptions = {
      text: '(re)Generate Invoice',
      type: 'danger',
      onClick: () => {
        if (this.Invoice.TemplateId == null) {
          notify('Please select a template first');
          return;
        }
        this.onPreview();
      },
    };

    this.reloadButtonOptions = {
      text: 'Reload document',
      type: 'danger',
      onClick: () => {
        this.ReloadDocument();
      },
    };

    this.editButtonOptions = {
      text: 'Edit Document',
      type: 'danger',
      onClick: () => {
        this.onEditDocument();
      },
    };

    this.finalizeButtonOptions = {
      text: 'Finalize Invoice',
      type: 'danger',
      onClick: () => {
        this.onFinalize();
      },
    };

    this.creditButtonOptions = {
      text: 'Credit Invoice',
      type: 'danger',
      onClick: () => {
        this.openLineSelection();
      },
    };

    this.downloadButtonOptions = {
      text: 'Download Invoice',
      type: 'danger',
      onClick: () => {
        this.download();
      },
    };

    this.isPaid = this.isPaid.bind(this);
    this.onTemplateIdChanged = this.onTemplateIdChanged.bind(this);
    this.onPayerChanged = this.onPayerChanged.bind(this);
  }

  getTitleText(): string {
    return 'Invoice - ' + this.Invoice?.InvoiceNumber;
  }

  isFinalized(): boolean {
    return this.Invoice?.IsFinalized;
  }

  // If the invoice is paid, we need to info on the date on which it was paid.
  isPaid(e) {
    if (this.Invoice?.IsPayed == true) {
      if (this.Invoice?.PaidOn != null) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.invoiceHeaderCategories = new DataSource({
      store: AspNetData.createStore({
        key: 'invoiceHeaderCategoryId',
        loadUrl: `${this.url}Invoice/InvoiceHeaderCategory/lookup`,
      }),
      sort: 'Label',
    });

    this.templates = new DataSource({
      store: AspNetData.createStore({
        key: 'TemplateId',
        loadUrl: `${this.url}TemplateEngine/Template/Lookup`,
      }),
      sort: 'TemplateLabel',
    });

    this.currencies = new DataSource({
      store: AspNetData.createStore({
        key: 'CurrencyId',
        loadUrl: `${this.url}Currency/lookup`,
      }),
      sort: 'CurrencyLabel',
    });
  }

  // When changing the InvoiceHeaderId, we need to reload the payer dropdown.
  // Todo: document section should move to here as well.
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.InvoiceHeaderId) {
      this.loadPayerDropdown();
    }
  }

  ReloadDocument() {
    this.BlobFile = null;
    this.pdfDocumentLoading = true;
    this.InvoiceSvc.getPdfDocument(this.InvoiceHeaderId)
      .pipe(
        finalize(() => {
          this.pdfDocumentLoading = false;
        })
      )
      .subscribe({
        next: (e) => {
          this.BlobFile = e;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
  }

  onCancelDraft(): void {
    this.InvoiceSvc.cancelDraft(this.Invoice.InvoiceHeaderId).subscribe({
      next: (x) => {},
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error cancelling draft', 3000);
      },
      complete: () => {
        this.save.emit();
        this.visibleChange.emit(false);
      },
    });
  }

  onPreview(): void {
    //if there is a blobfile, prompt the user if he want to continue
    if (this.BlobFile) {
      this.showWarningRegenerateInvoice = true;
      return;
    }

    this.pdfDocumentLoading = true;

    this.InvoiceSvc.prepareDraftDocument(
      this.Invoice.InvoiceHeaderId,
      this.Invoice.TemplateId
    )
      .pipe(
        finalize(() => {
          this.pdfDocumentLoading = false;
        })
      )
      .subscribe({
        next: (x) => {
          this.save.emit();
          this.InvoiceSvc.getPdfDocument(this.InvoiceHeaderId).subscribe({
            next: (e) => {
              this.BlobFile = e;
            },
            error: (err) => {
              const errorMessage = err.error.Errors.GeneralErrors[0];
              notify(errorMessage, 'error', 3000);
            },
          });
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error creating preview document', 3000);
        },
        complete: () => {},
      });
  }

  onEditDocument(): void {
    this.InvoiceSvc.getDraftDocumentLink(
      this.Invoice.InvoiceHeaderId
    ).subscribe({
      next: (x) => {
        window.open(x, '_blank');
      },
      error: (err) => {
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error', 3000);
      },
    });
  }

  onFinalize(): void {
    this.finalizeLoading = true;

    this.InvoiceSvc.finalizeDraft(this.Invoice.InvoiceHeaderId)
      .pipe(
        finalize(() => {
          this.serviceLoading = false;
          this.finalizeLoading = false;
        })
      )
      .subscribe({
        next: (x) => {
          this.Invoice = x;
          this.serviceLoading = true;
          this.Invoice.IsFinalized = true;
          this.loadPdfPreview();
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error finalizing draft', 3000);
        },
        complete: () => {
          this.save.emit();
        },
      });
  }

  onSaveClick(): void {
    if (this.Invoice.IsFinalized) {
      this.InvoiceSvc.updateInvoice(this.Invoice).subscribe({
        next: (x) => {},
        error: (err) => {
          let errorMessage = err?.error?.Errors?.GeneralErrors[0];

          if (!errorMessage) {
            errorMessage = 'An error occurred while saving the invoice.';
          }

          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.BlobFile = null;
          this.save.emit(this.Invoice);
        },
      });
    } else {
      this.DraftInvoiceSvc.updateDraftInvoice(this.Invoice).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {
          this.BlobFile = null;
          this.save.emit(this.Invoice);
        },
      });
    }
  }

  onTemplateIdChanged(e): void {
    if (e.value != e.previousValue && this.Invoice) {
      this.DraftInvoiceSvc.updateDraftInvoice(this.Invoice).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error', 3000);
        },
        complete: () => {},
      });
    }
  }

  onPayerChanged(e): void {
    if (e.value != e.previousValue && this.Invoice) {
      this.DraftInvoiceSvc.updateDraftInvoice(this.Invoice).subscribe({
        next: (x) => {},
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'Error changing payer on the invoice', 3000);
        },
        complete: () => {},
      });
    }
  }

  handleCancelClick(): void {
    this.BlobFile = null;
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  handleVisible(e: boolean): void {
    this.BlobFile = null;
    this.visible = e;
    this.visibleChange.emit(this.visible);
  }

  openLineSelection(): void {
    if (!this.showWarningPopupReopenCharge) {
      this.showWarningPopupReopenCharge = true;
      return;
    }
    this.creditLineSelectionFormVisible = true;
  }

  onCanceledCreditLinesSelectionForm(): void {
    this.creditLineSelectionFormVisible = false;
  }

  onSavedCreditLinesSelectionForm(): void {
    this.creditLineSelectionFormVisible = false;
    this.save.emit();
    this.visibleChange.emit(false);
  }

  noDontReopenCharge() {
    this.showWarningPopupReopenCharge = false;
    this.reopensCharge = false;
    this.creditLineSelectionFormVisible = true;
  }

  yesReopenCharge() {
    this.showWarningPopupReopenCharge = false;
    this.reopensCharge = true;
    this.creditLineSelectionFormVisible = true;
  }

  yesRegenerate() {
    this.pdfDocumentLoading = true;
    this.InvoiceSvc.prepareDraftDocument(
      this.Invoice.InvoiceHeaderId,
      this.Invoice.TemplateId
    ).subscribe({
      next: (e) => {},
      error: (err) => {
        this.pdfDocumentLoading = false;
        const errorMessage = err.error.Errors.GeneralErrors[0];
        notify(errorMessage, 'Error creating preview document', 3000);
      },
      complete: () => {
        this.save.emit();
        this.InvoiceSvc.getPdfDocument(this.InvoiceHeaderId)
          .pipe(
            finalize(() => {
              this.pdfDocumentLoading = false;
            })
          )
          .subscribe({
            next: (e) => {
              this.BlobFile = e;
            },
            error: (err) => {
              const errorMessage = err.error.Errors.GeneralErrors[0];
              notify(errorMessage, 'error', 3000);
            },
          });
      },
    });

    this.showWarningRegenerateInvoice = false;
  }

  noRegenerate() {
    this.showWarningRegenerateInvoice = false;
  }

  // When the popup is shown, check if we need to load the PDF document or not.
  // Todo => check if this doesn't have to be true at any time, and if we can remove the check on loadDocument.
  // DocumentExist Flag on the InvoiceHeaderTable should be used to determine if we have to load or not.
  onShownEvent() {
    this.InvoiceSvc.getInvoice(this.InvoiceHeaderId).subscribe({
      next: (e) => {
        this.Invoice = e;

        if (!this.pdfDocumentLoading) {
          this.loadPdfPreview();
        }
      },
      error: (err) => {
        notify('Invoice could not be loaded correclty', 'error', 3000);
        this.visible = false;
      },
    });
  }

  loadPayerDropdown() {
    if (this.InvoiceHeaderId != null)
      this.clients = new DataSource({
        store: AspNetData.createStore({
          key: 'NameId',
          loadUrl: `${this.url}Invoice/InvoiceHeader/GetPayerLookup`,
          loadParams: { InvoiceHeaderId: this.InvoiceHeaderId },
        }),
        sort: 'LegalName',
      });
  }

  loadPdfPreview() {
    this.pdfDocumentLoading = true;
    this.InvoiceSvc.getPdfDocument(this.InvoiceHeaderId)
      .pipe(
        finalize(() => {
          this.pdfDocumentLoading = false;
          this.loadPdfDocumentChange.emit(false);
        })
      )
      .subscribe({
        next: (e) => {
          this.BlobFile = e;
        },
        error: (err) => {
          const errorMessage = err.error.Errors.GeneralErrors[0];
          notify(errorMessage, 'error', 3000);
        },
      });
  }

  download = () => {
    const fileName = this.Invoice.InvoiceNumber;

    saveAs(this.BlobFile, fileName + '.pdf');
  };
}

@NgModule({
  declarations: [FinanceDraftInvoiceComponent],
  exports: [FinanceDraftInvoiceComponent],
  bootstrap: [FinanceDraftInvoiceComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    DxDataGridModule,
    DxButtonModule,
    DxScrollViewModule,
    PDFPreviewModule,
    FormPopupModule,
    DxToolbarModule,
    FinanceDraftInvoiceDetailsModule,
    DxLoadPanelModule,
    CreditLinesSelectionFormModule,
    DxPopupModule,
  ],
})
export class FinanceDraftInvoiceModule {}
