<dx-data-grid
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  id="servicesGrid"
  [dataSource]="dataSource"
  [showBorders]="true"
  [remoteOperations]="true"
  [showBorders]="true"
  [repaintChangesOnly]="true"
  [wordWrapEnabled]="true"
>
  <dxo-load-panel [enabled]="true" />
  <dxo-scrolling mode="virtual" rowRenderingMode="virtual" />
  <dxo-sorting mode="multiple" />
  <dxo-column-chooser [enabled]="false" />
  <dxo-search-panel [visible]="false" />
  <dxo-editing
    mode="popup"
    [allowAdding]="true"
    [allowUpdating]="false"
    [allowDeleting]="true"
  />

  <dxo-toolbar>
    <dxi-item location="after" locateInMenu="never">
      <dx-button
        icon="plus"
        type="default"
        stylingMode="contained"
        (onClick)="onAdd()"
      />
    </dxi-item>
    <dxi-item
      location="after"
      widget="dxButton"
      locateInMenu="never"
      [options]="{
        text: '',
        icon: 'refresh',
        stylingMode: 'text',
        onClick: refreshData
      }"
    />
    <dxi-item location="after" name="columnChooserButton" locateInMenu="auto" />
    <dxi-item location="after" name="searchPanel" locateInMenu="never" />
  </dxo-toolbar>

  <dxi-column
    dataField="ServiceTypeId"
    caption="Service Type"
    calculateDisplayValue="ServiceTypeLabel"
  >
    <dxo-lookup
      [dataSource]="serviceTypesDataSource?.store()"
      displayExpr="ServiceTypeLabel"
      valueExpr="ServiceTypeId"
    />
  </dxi-column>
  <dxi-column
    dataField="CaseTypeId"
    caption="Case Type"
    calculateDisplayValue="CaseTypeLabel"
  >
    <dxo-lookup
      [dataSource]="caseTypesDataSource?.store()"
      displayExpr="CaseTypeLabel"
      valueExpr="CaseTypeId"
    />
  </dxi-column>
  <dxi-column dataField="CoverageId" caption="Coverage" />
  <dxi-column dataField="Amount" caption="Price" format="currency" />
  <dxi-column type="buttons">
    <dxi-button icon="edit" [onClick]="onEdit" />
    <dxi-button name="delete" />
  </dxi-column>

  <dxo-master-detail [enabled]="true" template="detail" />
  <div *dxTemplate="let details of 'detail'">
    <div>
      <dx-data-grid
        [allowColumnResizing]="true"
        [allowColumnReordering]="true"
        [dataSource]="details.data.QuoteWorkCodeLines"
        title="WorkCodes"
        accessKey="Id"
      >
        <dxi-column
          dataField="WorkCodeLabel"
          caption="WorkCode Label"
          alignment="center"
        />
        <dxi-column
          dataField="Description"
          caption="Description"
          alignment="center"
        />

        <dxi-column
          dataField="UnitPrice"
          caption="UnitPrice"
          alignment="center"
        >
          <dxo-format type="currency" precision="2" />
        </dxi-column>
        <dxi-column dataField="Quantity" caption="Quantity" alignment="center">
          <dxo-format precision="0" />
        </dxi-column>
        <dxi-column dataField="Amount" caption="Amount" alignment="center">
          <dxo-format type="currency" precision="2" />
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</dx-data-grid>

<fee-calculator-wizard-services-selector
  [(showPopup)]="showServicePopup"
  (onSaveData)="onAddService($event)"
  [quote]="selectedQuoteLine"
/>
