import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxNumberBoxModule,
  DxTabPanelModule,
} from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fee-calculator-billing-matrix-lines',
  templateUrl: './fee-calculator-billing-matrix-lines.component.html',
  styleUrls: ['./fee-calculator-billing-matrix-lines.component.scss'],
})
export class FeeCalculatorBillingMatrixLinesComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @Input() source: any;

  @Input() filteredWorkCodes: any;

  @Input() readOnly: boolean;

  @Input() caseTypeId: number;

  workCodes: DataSource;

  parameterTypes: any;

  url = environment.CalystaApiBaseURL + 'api/';

  billingMatrixId: number;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };

    this.parameterTypes = ['Patents', 'Trademarks', 'Designs', 'Other'];
  }

  ngOnInit(): void {
    this.workCodes = new DataSource({
      store: AspNetData.createStore({
        key: 'WorkCodeId',
        loadUrl: `${environment.CalystaApiBaseURL}api/Invoice/WorkCode/lookup`,
      }),
      sort: ['WorkCodeLabel', 'WorkCodeDescription'],
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  imports: [CommonModule, DxDataGridModule, DxNumberBoxModule, DxTabPanelModule],
  declarations: [FeeCalculatorBillingMatrixLinesComponent],
  exports: [FeeCalculatorBillingMatrixLinesComponent],
})
export class FeeCalculatorBillingMatrixLinesModule {}
