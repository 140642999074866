import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxDataGridModule } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { EditorPreparingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'finance-creditcollect-reminder-history',
  templateUrl: './finance-creditcollect-reminder-history.component.html',
  styleUrls: ['./finance-creditcollect-reminder-history.component.scss'],
})
export class FinanceCreditcollectReminderHistoryComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  dataSource: any;

  saveButtonOptions: any;
  cancelButtonOptions: any;

  url = environment.CalystaApiBaseURL + 'api/';

  constructor() {
    this.saveButtonOptions = {
      type: 'default',
      stylingMode: 'contained',
      text: 'Save',
      onClick: () => {
        this.dataGrid.instance.saveEditData();
      },
    };

    this.cancelButtonOptions = {
      type: 'default',
      text: 'Cancel',
      onClick: () => {
        this.dataGrid.instance.cancelEditData();
      },
    };
  }

  ngOnInit() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        loadUrl: `${this.url}CreditCollect/AmountDue/ReminderHistory`,
        updateUrl: `${this.url}CreditCollect/AmountDue/ReminderHistory`,
        onBeforeSend: (method, ajaxOptions) => {
          ajaxOptions.xhrFields = { withCredentials: false };
        },
      }),
    });
  }

  refreshData = () => {
    this.dataGrid.instance.refresh();
  };

  onEditorPreparing(e: EditorPreparingEvent) {
    if (e.parentType === 'dataRow' && e.dataField === 'Outcome') {
      e.editorName = 'dxTextArea';
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    FinancialValutaKpiModule,
    FinancialNumberKpiModule,
  ],
  providers: [],
  declarations: [FinanceCreditcollectReminderHistoryComponent],
  exports: [FinanceCreditcollectReminderHistoryComponent],
})
export class FinanceCreditcollectReminderHistoryModule {}
