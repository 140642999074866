<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <titlebar titleText="New Quote" />
    <div class="separator"></div>
    <dx-tab-panel [swipeEnabled]="false" *ngIf="quote">
      <dxi-item title="1. Client/Prospect" icon="user">
        <fee-calculator-wizard-organization
          (stepCompleted)="organizationStepCompleted($event)"
          [quote]="quote"
        />
      </dxi-item>
      <dxi-item
        title="2. Services"
        icon="orderedlist"
        [visible]="organizationCompleted"
      >
        <fee-calculator-wizard-services
          (stepCompleted)="serviceStepCompleted($event)"
          [quote]="quote"
        />
      </dxi-item>
      <dxi-item
        title="3. Overview"
        icon="datausage"
        [visible]="!servicesCompleted"
      >
        <!-- Content for Tab 3 -->
      </dxi-item>
    </dx-tab-panel>
  </div>
</dx-scroll-view>
